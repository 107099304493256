import React from 'react'
import Container from 'react-bootstrap/Container';

const Header = () => {

  return (
    <> 
    <header className='header-home '  > 
    <Container fluid className='hero px-5'>
      <h2>Home</h2>           
     </Container>
     </header>
     </>
  )
}

export default Header
