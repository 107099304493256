import React from 'react'
import Container from 'react-bootstrap/Container';

const HeaderServices = () => {
  return (
    <> 
    <header className='header-home '  > 
    <Container fluid className='hero px-5'>
      <h2>Services</h2>           
     </Container>
     </header>
     </>

  )
}

export default HeaderServices