import React from 'react'
import Container from 'react-bootstrap/Container';



const HeaderContact = () => {


  return (
    <> 
    <header className='header-home '  > 
    <Container fluid className='hero px-5'>
      <h2>Contact</h2>           
     </Container>
     </header>
     </>
  )
}

export default HeaderContact
