import React from 'react'
import { Container } from 'react-bootstrap'
import './aboutheader.css'

//import Menu from './Menu'

const Aboutheader = () => {
  return (
    <> 
    <header className='about-home '  > 
    <Container fluid className='hero px-5'>
      <h2>About</h2>           
     </Container>
     </header>
     </>

  )
}

export default Aboutheader