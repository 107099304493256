import React from 'react'

const Body = () => {
  return (
    <>
      <section className='redbg'></section>
      <section className='bluevioletbg'></section>
      <section className='greenbg'></section>
      <section className='graybg'></section>
      <section className='palegreenbg'></section>
    </>  
  )
}

export default Body