import React from 'react'
import Menu from '../component/Menu';
import Body from '../component/Body';
import HeaderServices from '../component/HeaderServices';

const Services = () => {
  return (
    <>
       <Menu />
       <HeaderServices />
       <Body />
    </>
  )
}

export default Services
