import React from 'react'


import Menu from '../component/Menu';
import Header from '../component/Header';
import Body from '../component/Body';

const Home = () => {



  return (
    <>
       <Menu /> 
       <Header /> 
       <Body />
    </>
  )
}

export default Home
